require 'src/coffee/controls/grid'

setup = ({$element, options, $ctx = $(document)}) ->
    dataOptions =
        selection: $element.data 'selection'
        paging: $element.data 'paging'
        minimal: $element.data 'minimal'
        conversion:
            numbers:
                minimumFractionDigits: 2 # TODO: add data api or something
                maximumFractionDigits: 2 # TODO: add data api or something

    options = $.extend {}, dataOptions, options

    $element.staticgrid options

    return $.Deferred().resolve().promise()

module.exports = {
    setup
}

module.exports.__esModule = true
module.exports.default = setup
